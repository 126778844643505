@charset "UTF-8";

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  overflow-y: scroll;
}

.wrapper {
  min-height: 100vh;
  min-height: var(--doc-height);
}

nav .dot {
  height: 0.25rem;
  width: 0.25rem;
  background-color: #bbb;
  border-radius: 50%;
  display: block;
}

nav .navlink {
  position: relative;
}

nav .navlink svg {
  position: absolute;
  bottom: -0.4rem;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 0.4rem;
  fill: transparent;
  transition: fill .25s ease-in-out;
}

nav .navlink:hover svg {
  fill: #bbb;
}

nav .navlink svg.active {
  fill: #940025;
}

nav a {
  transition: color .25s ease-in-out;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

nav .navlink a.active {
  color: #940025;
}

.mobileNav {
  transform: translateY(-6.25rem);
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.5);
  background: #940025;
  color: rgb(255, 255, 255);
  z-index: 10;
}

.mobileNav.mounted {
  transition: transform 400ms ease-in-out;
}

.showMobileNav {
  transform: translateY(0);
}

.mobileNav a,
.mobileNavIcon,
.mobileMenuOptions a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.mobileNavIcon {
  transform: scale(0.9);
}

.mobileNavIcon svg {
  transition: all 400ms ease-in-out;
  /* will-change: transform; */
}

.mobileNavIcon .hamMiddle {
  transition-duration: 0.25s;
}

.mobileNavIcon.close .hamTop {
  transform: translateX(-0.313rem) translateY(0.375rem) rotate(45deg);
}

.mobileNavIcon.close .hamMiddle {
  opacity: 0;
}

.mobileNavIcon.close .hamBottom {
  transform: translateX(-0.313rem) translateY(-0.438rem) rotate(-45deg);
}

.menuBubble {
  background: #940025;
  border-radius: 50%;
  z-index: 5;
  opacity: 0;
  width: 0;
  height: 0;
  top: -8rem;
  right: -12rem;
  transition: all 400ms ease-in-out;
}

.menuBubble.open {
  opacity: 1;
  width: 24rem;
  height: 24rem;
  transform: scale(1);
  box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.5);
}

@media (min-width: 675px) {
  .menuBubble {
    top: -12rem;
    right: -12rem;
  }

  .menuBubble.open {
    width: 28rem;
    height: 28rem;
  }
}

@media (min-width: 860px) {
  .menuBubble {
    top: -14rem;
    right: -10rem;
  }

  .menuBubble.open {
    width: 32rem;
    height: 32rem;
  }
}

@media (min-width: 950px) {
  .menuBubble {
    top: -20rem;
    right: -10rem;
  }

  .menuBubble.open {
    width: 36rem;
    height: 36rem;
  }
}

.mobileMenuOptions {
  z-index: 6;
  right: 50%;
  transform: translateX(200%);
  opacity: 0;
  transition: all 400ms ease-in-out;
}

.mobileMenuOptions.open {
  opacity: 1;
  transform: translateX(50%);
}

.mobileMenuOptions ul {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.mobileMenuOptions li {
  color: rgba(255, 255, 255, 0.66);
  position: relative;
  transition: opacity 200ms ease-in-out;
}

.mobileMenuOptions li .active {
  color: white;
  font-weight: 500;
}

.mobileMenuOptions li svg {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 0.4rem;
  fill: white;
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.mobileMenuOptions li svg.active {
  opacity: 1;
}

.testing {
  opacity: 0;
  transition: all 1s ease-in-out;
}

.testing.fade {
  animation-name: fadein;
  animation-duration: 500ms;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadein {
  0% {
    opacity: 0;
    transform: translateY(0.625rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.loadingImage {
  width: 0;
  opacity: 0;
  transition: opacity 1.1s ease-in-out;
}

.loadingImage.loaded {
  width: auto;
  opacity: 1;
}

.loader {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  background: linear-gradient(0deg, rgba(148, 0, 37, 0) 33%, #940025 100%);
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(0deg);
  z-index: -1;
}

.loader::after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 3.75rem;
  height: 3.75rem;
  border-radius: 50%;
  background: white;
  z-index: -1;
}

@keyframes rotation {
  0% {
    transform: translate(-50%, -50%) rotate(0deg)
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg)
  }
}

@media screen and (max-height: 350px) {
  .loader {
    top: 67%;
  }
}

@media screen and (max-height: 260px) {
  .loader {
    top: 75%;
  }
}

a,
button {
  transition: all 200ms ease-in-out, font-size 0s;
}

.aboutPanel {
  max-height: 0;
  transition: max-height 400ms ease-in-out;
  overflow: hidden;
}

.aboutList li {
  padding-left: 1.25rem;
  position: relative;
}

.aboutList li::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0.52rem;
  width: 0.375rem;
  height: 0.375rem;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;utf8,<?xml version='1.0' encoding='utf-8'?><svg role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'><circle cx='50' cy='50' r='50' fill='%23d1d5db' /></svg>");
}

.contactSendIcon path {
  stroke: white;
}

.bookPurchaseIcon path {
  stroke: theme('colors.indigo.900');
}

.homeAuthorImage {
  position: relative;
}

.homeAuthorImage .mainImage {
  z-index: 0;
}

.homeAuthorImage .ambientEffect {
  position: absolute;
  top: 50%;
  left: 50%;
  filter: blur(50px);
  transform: translate(-50%, -50%) scale(1.33, 1);
  /*scale(1.25, 0.9)*/
  z-index: -10;
  -webkit-transform: translate(-50%, -50%) scale(1.33, 1) translateZ(0);
  -webkit-perspective: 1000;
  -webkit-backface-visibility: hidden;
}

.loadingImage.loadedPart {
  width: auto;
  opacity: 0.33;
}

.disabledSubmit,
.disabledSubmit:hover,
.disabledSubmit:active {
  cursor: not-allowed;
  background: theme('colors.neutral.400') !important;
  border: theme('colors.neutral.400') !important;
}